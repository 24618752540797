import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { PageLoader, Table } from "../../Components";
import { TableColumns } from "./ParticipantCol";
import { campaignClient } from "../../api";
import { CSVLink } from "react-csv";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

export type Report = {
  email: string;
  lastactivity: string;
  loadedPage: [];
  requestDemo: [];
};
function Participants() {
  const { isLoading: isLoadingParticipants, data: participants } = useQuery(
    "getReport",
    async () => {
      const res: Report[] = await campaignClient.call("getreport", {});
      return res;
    }
  );

  const [filterTable, setFilterTable] = useState("");

  const data = useMemo(() => {
    if (participants && participants.length > 0) {
      return participants.map((participant) => {
        return {
          email: participant.email ? participant.email : "NA",
          updateTime: participant.lastactivity
            ? new Date(participant.lastactivity).toLocaleString()
            : "NA",
          loadedPage: participant.loadedPage
            ? participant.loadedPage.map((i: Date) => new Date(i).toLocaleString()).join("; ")
            : "NA",
          requestDemo: participant.requestDemo
            ? participant.requestDemo.map((i: Date) => new Date(i).toLocaleString()).join("; ")
            : "NA",
        };
      });
    }
  }, [participants]);

  const reportData = useMemo(() => {
    if (data) {
      return data.map((rp) => {
        return {
          "Last Action": rp.updateTime,
          "Request Demo": rp.requestDemo,
          "Loaded Page": rp.loadedPage,
          Email: rp.email,
        };
      });
    }
  }, [data]);

  const columns = TableColumns();

  if (isLoadingParticipants) {
    return <PageLoader />;
  }

  return !isLoadingParticipants && participants && participants.length > 0 ? (
    <>
      <div className="main__head">
        <h2 className="main__title">Participants</h2>

        <div className="main__actions">
          <div className="search search--alt">
            <div className="search__row search__row--input">
              <CSVLink
                filename={`Campaign-Winner-Report-${new Date().toLocaleDateString()}`}
                className="btn-admin"
                data={reportData ? reportData : ""}
                asyncOnClick={true}
                target="_blank">
                Download Report
              </CSVLink>
            </div>
          </div>
        </div>
      </div>

      <div className="main__body">
        <div className="tabs js-tabs">
          <div className="search search--alt search--medium main__search">
            <div className="search__row">
              <label htmlFor="q" className="hidden">
                Search
              </label>
              <input
                type="search"
                name="q"
                id="q"
                value={filterTable}
                placeholder="Search"
                className="search__field"
                onChange={(e) => setFilterTable(e.target.value)}></input>
              <button type="submit" className="search__btn">
                <svg className="ico-search">
                  <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="tabs__body mt-5">
        <div className="table table--alt table--tabs table--big">
          <Table
            columns={columns}
            data={data ? data : []}
            tablePageSize={15}
            filterValue={filterTable}
          />
        </div>
      </div>
    </>
  ) : (
    <p>Currently there are no any participants to display.</p>
  );
}

export default Participants;
