import React from "react";

export const Confirm = () => {

  return (
    <div className="container-ui">
      <div className="confirmation main-ui">
        <div className="left">
          <img src="/assets/images/logo-spray.png" className="sprayLogo" alt="Spray Logo" />
          <p>Thank you for your interest.</p>

          <p>We'll be in touch shortly to book a meeting.</p>

          <p>Looking forward to connecting!</p>
        </div>
      </div>
      <div className="footer-ui">
        <a href="https://www.wilyglobal.com" target="_blank">
          <img src="/assets/images/logo.png" alt="Logo" className="logoFooter" />
        </a>
      </div>
    </div>
  );
};
