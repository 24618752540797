import "./index.css";
import { Authenticator, translations } from "@aws-amplify/ui-react";
import { Hub } from "aws-amplify";
import { useEffect, useState } from "react";
import { publicCampaignClient } from "../../api";
import { I18n } from "aws-amplify";
import _ from "lodash";
import { removeWhiteSpacesForPassword } from "../../helpers/utils";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../stores";

I18n.putVocabularies(translations);

function Login() {
  const [userName, setUserName] = useState(null);
  const [userStatus, setUserStatus] = useState("");
  const { authStore } = useStores();

  const navigate = useNavigate();

  useEffect(() => {
    const getVerifyUser = async () => {
      const user = await authStore.verifyLogin();

      if (user) {
        navigate("/admin/overview");
      }
    };

    getVerifyUser();
  }, [userName]);

  useEffect(() => {
    const logout = async () => {
      await authStore.logout();
      navigate("/admin/login", { replace: true });
    };

    Hub.listen("auth", (data: any) => {
      if (!data.payload.data) {
        return;
      }

      if (
        (data.payload.data.attributes && data.payload.data.attributes.profile !== "Admin") ||
        data.payload.data.attributes.profile !== "Standard"
      ) {
        logout();
      } else {
        setUserName(data.payload.data.username);
        setUserStatus(data.payload.data.message);
      }
    });
  }, [Hub]);

  useEffect(() => {
    removeWhiteSpacesForPassword();
  });

  useEffect(() => {
    customizeValidationErrorMessage();
    customizeForgotTempPasswordErrorMessage();
  }, [userStatus, userName]);

  const customizeValidationErrorMessage = () => {
    const originalMessageForValidationError =
      "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+";
    const translatedMessageForValidationError =
      "Please provide a valid email address as your username.";
    if (
      userStatus ==
      "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+"
    ) {
      I18n.putVocabularies({
        en: {
          [originalMessageForValidationError]: [translatedMessageForValidationError],
        },
      });
    }
  };

  const customizeForgotTempPasswordErrorMessage = async () => {
    const originalMessageForForgotTempPassword =
      "User password cannot be reset in the current state.";
    const translatedMessageForForgotTempPassword =
      "Temporary password has been resent to the user.";
    if (userName !== null && userStatus === "User password cannot be reset in the current state.") {
      I18n.putVocabularies({
        en: {
          [originalMessageForForgotTempPassword]: [translatedMessageForForgotTempPassword],
        },
      });
      try {
        await publicCampaignClient.call("signUp", {
          userName: userName,
        });
      } catch (error) {
        console.log(error);
      }
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  return (
    <div className="from-access">
      <h5 className="form__title">Campaign Login</h5>
      <div className="form__body">
        <Authenticator hideSignUp={true} />
      </div>
    </div>
  );
}

export default Login;
