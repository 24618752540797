import { useEffect, useState } from "react";
import { Outlet, useNavigate, useMatch } from "react-router-dom";
import MenuFooter from "./MenuFooter";
import SideBar from "./SideBar";
import SideBarHeader from "./SideBarHeader";
import { useStores } from "../../stores";

function Layout() {
  const match = useMatch({ path: "participant/:key", end: true });

  const { authStore } = useStores();

  const [toggle, setToggle] = useState(false);

  const [mobileToggle, setMobileToggle] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleClick = (event: any) => {
      setToggle(false);
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, []);

  const logOut = async () => {
    try {
      await authStore.logout();

      navigate("/admin/login", { replace: true });
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };

  return (
    <div className={`wrapper ${mobileToggle ? "toggled-nav" : ""}`}>
      <div className="sidebar">
        <SideBarHeader mobileNav={mobileToggle} handleNav={setMobileToggle} handleLogOut={logOut} />

        <SideBar />

        <MenuFooter />
      </div>

      <div className="content">
        <header className="header">
          <div className="shell">
            <div className="header__inner">
              <h4 className="header__title">WILY Demo Reel</h4>

              <div className="header__actions">
                <div className={`account-menu ${toggle ? "is-active" : ""}`}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setToggle(!toggle);
                    }}
                    className="account-menu__trigger js-account-menu-trigger">
                    <span>{authStore.user.name.substring(0, 1)}</span>
                  </a>

                  <ul className="account-menu__dropdown">
                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          logOut();
                        }}>
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className={`main ${match ? "main--alt" : ""}`}>
          <div className="shell">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
