import { Row, TableProps } from "react-table";

export const TableColumns = () => {
  const COLUMNS = [
    {
      Header: "No.",
      accessor: "colNumber",
      width: "1%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1;
        return nmb;
      },
    },

    {
      Header: "Last Action",
      accessor: "updateTime",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1;
        if (rowB.values[columnId] > rowA.values[columnId]) return -1;
        return 0;
      },
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].updateTime
          ? TableInfo.data[TableInfo.row.index].updateTime.toLocaleString()
          : "";
        return dateString;
      },
    },
    {
      Header: "Request Demo",
      accessor: "requestDemo",
      width: "30%",
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].requestDemo;
        return dateString;
      },
    },
    {
      Header: "Loaded Page",
      accessor: "loadedPage",
      width: "30%",
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].loadedPage;
        return dateString;
      },
    },

    {
      Header: "Email Address",
      accessor: "email",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].email ? (
          <p>{TableInfo.data[TableInfo.row.index].email}</p>
        ) : (
          "N/A"
        );
      },
    },
  ];
  return COLUMNS;
};
