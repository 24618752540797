import { abort } from "process";
import { Spinner } from "react-bootstrap";

function Loader(props: { Color?: string }) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        textAlign: "center",
        padding: "200px 0",
        color: props.Color || "",
        position: "absolute",
        backgroundColor: "#00000085",
      }}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">
          <i className="fas fa-circle-notch fa-spin"></i>
        </span>
      </Spinner>
    </div>
  );
}

export default Loader;
