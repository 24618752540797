import { Route, Navigate } from "react-router-dom";
import { Overview, Participants,  Login, Closed } from "../Pages";
import { BaseLayout, Layout } from "../Components";
import Landing from "../Pages/Open/Landing";
import { Confirm } from "../Pages/Open/Confirm";


export default (
  <>
    <Route path="/" element={<Landing />} />
    <Route path="confirm" element={<Confirm />} />

    <Route path="closed" element={<Closed />} />
    <Route
      path="/admin/login"
      element={
        <BaseLayout>
          <Login />
        </BaseLayout>
      }
    />
    <Route path="/admin" element={<Layout />}>
      {/* <Route index element={<Navigate to="/overview" />} /> */}
      <Route path="overview" element={<Overview />} />
      <Route path="participants" element={<Participants />} />
    </Route>
  </>
);
