import { Outlet, Route, Routes } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import CustomRoutes from "./Config/Routes";
import { useStores, StoreContext } from "./stores";
import { Login } from "./Pages";

import { Auth } from "aws-amplify";
import { setupApiClient } from "./api";
import Admins from "./Pages/Admins";

import { useEffect, useState } from "react";
import { Layout } from "./Components";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

function App() {
  setInterval(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const { refreshToken } = cognitoUser.getSignInUserSession();
      cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
        setupApiClient(session.idToken.jwtToken);
      });
    } catch (e) {
      throw e;
    }
  }, 1200000);

  const stores = useStores();

  const { route, user } = useAuthenticator((context) => [context.route]);

  const [profile, setProfile] = useState("");

  const getProfile = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      setProfile(user.attributes.profile);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getProfile();

    if (route === "authenticated" && user) {
      if (
        (user.attributes && user.attributes.profile == "Admin") ||
        (user.attributes && user.attributes.profile == "Standard")
      ) {
        stores.authStore.setup();
        stores.authStore.setUserName(user.username!, "", true);
      } else {
        window.vex.dialog.alert({
          unsafeMessage: `This is not an authorized account to access this portal.`,
          callback: async () => {
            await Auth.signOut({ global: true });
            return;
          },
        });
      }
    }
  }, [user]);

  return (
    <StoreContext.Provider value={stores}>{<Routes>{CustomRoutes}</Routes>}</StoreContext.Provider>
  );
}

export default App;
