import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import { getSessionClient, publicCampaignClient } from "../../../api";
import "../assetsUI/css/styles.css";
import { emailRegex } from "../../../helpers/utils";
import Loading from "../../../Components/Loading";

type Register = { sessionKey: string; error: string; message: string; code: string };

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

export default function Landing() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [showLink, setShowLink] = useState(false);
  const [loading, setLoading] = useState(false);

  const sk = getSessionClient().__sessionKey;

  useEffect(() => {
    siteLoad();
  }, []);

  const siteLoad = async function () {
    try {
      const em = window.location.search.split("em=")[1];
      const isValidEmail = emailRegex.test(em);
      if (em && isValidEmail) {
        setEmail(em.toLowerCase());
      }
      await publicCampaignClient.call("siteload", {
        sessionKey: sk,
        email: isValidEmail ? em : "",
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleBtnClick = async function () {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { sessionKey, error, code, message } = await publicCampaignClient.call<Register>(
        "register",
        {
          sessionKey: sk,
          email,
        }
      );
      if (sessionKey || code === "email_in_use") {
        navigate("/confirm");
      } else if (error && message) {
        window.vex.dialog.alert({
          unsafeMessage: message,
          callback: () => {
            setLoading(false);
            return;
          },
        });
      }

      setLoading(false);
    } catch {
      window.vex.dialog.alert({
        unsafeMessage: "Something went wrong. Please try again latter.",
        callback: () => {
          setLoading(false);
          return;
        },
      });
    }
  };

  return (
    <div className="container-ui">
      {loading && <Loading />}
      <div className="landing-ui main-ui">
        <div className="main-flex">
          <div className="left">
            <div className="mobHide">
              <img src="/assets/images/logo-spray.png" className="sprayLogo" alt="Spray Logo" />
              <p>
                SPRY digital experience platform enables you to easily build and deliver the
                marketing activation solutions you need.
              </p>

              <p>
                SPRY powers ideas and solves technical challenges with a range of prebuilt and
                custom enterprise-grade solutions.
              </p>

              <p>
                As&nbsp;
                <span>Activation Middleware</span>, SPRY enables you to develop your solution,
                securely integrate with any system, and engage your audience across all digital
                touch points.
              </p>
            </div>
          </div>

          <div className="right">
            <div className="videoPlayer">
              <ReactPlayer
                url="https://player.vimeo.com/video/533211049?autoplay=1&color=ffffff&title=0&byline=0&portrait=0"
                playing={false}
                loop={false}
                // height={"auto"}
                onStart={() => {
                  setShowLink(true);
                }}
                controls={true}
                style={{
                  maxWidth: "100%",
                  margin: "0 auto",
                }}
              />
            </div>
            <h3>Intrigued? Let's connect.</h3>

            {email && showLink && (
              <button className="btn" onClick={handleBtnClick}>
                Click to book a meeting
              </button>
            )}
            <div className="desktopHide">
              <img src="/assets/images/logo-spray.png" className="sprayLogo" alt="Spray Logo" />
              <p>
                SPRY digital experience platform enables you to easily build and deliver the
                marketing activation solutions you need.
              </p>

              <p>
                SPRY powers ideas and solves technical challenges with a range of prebuilt and
                custom enterprise-grade solutions.
              </p>

              <p>
                As
                <span>Activation Middleware</span>, SPRY enables you to develop your solution,
                securely integrate with any system, and engage your audience across all digital
                touch points.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-ui">
        <a href="https://www.wilyglobal.com" target="_blank">
          <img src="/assets/images/logo.png" alt="Logo" className="logoFooter" />
        </a>
      </div>
    </div>
  );
}
