import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { campaignClient } from "../../api";
import { PageLoader, Graph } from "../../Components";
import dayjs from "dayjs";
import { getDaysBetween2Dates, getPastMonths } from "../../helpers/utils";
import { Report } from "../Participants";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };
  
function Overview() {

  const [selectedmonths, setSelectedmonths] = useState(2);

  const { isLoading: isLoadingParticipants, data: participants } = useQuery(
    "getreport",
    async () => {
      const res: Report[] = await campaignClient.call(
        "getreport",
        {}
      );     
      return res;
    }
  );


 const stats = participants?.length

  const data = useMemo(() => {
    if (participants && participants.length > 0) {
      return participants.map((participant) => {
        return {
        
          email: participant.email,
          creationTime: new Date(participant.lastactivity),
    
        };
      });
    }
  }, [participants]);

  const graphLabels = useMemo(() => {
    if (data) {
      if ([12, 6, 3].includes(+selectedmonths)) {
        const xlables = getPastMonths(+selectedmonths).reverse();
        const ylables = xlables.map(
          (m) =>
            data.filter((p) => dayjs(p.creationTime).format("MMM YY") === m)
              .length
        );
        return { x: xlables, y: ylables };
      } else {
        const endDate = dayjs().toISOString();
        let startDate = dayjs().subtract(7, "day").toISOString();
        if (selectedmonths === 1) {
          startDate = dayjs()
            .set("month", dayjs().get("month") - 1)
            .toISOString();
        }

        if (selectedmonths === 2) {
          startDate = dayjs().subtract(14, "day").toISOString();
        }
        const xlables = getDaysBetween2Dates(startDate, endDate).map((date) =>
          dayjs(date).format("MMM DD")
        );
        const ylables = xlables.map(
          (m) =>
            data.filter(
              (p) =>
                dayjs(p.creationTime).format("MMM DD YYYY") ===
                m + " " + dayjs().year()
            ).length
        );

        return { x: xlables, y: ylables };
      }
    }
  }, [selectedmonths, data]);

 

  if (
    // !stats ||
    isLoadingParticipants
  ) {
    return <PageLoader />;
  }

  return !isLoadingParticipants &&
    participants &&
    participants.length > 0 ? (
    <>
      <div className="main__head">
        <h2 className="main__title">Overview</h2>
        {/* 
        <div className="main__actions">
          <li>
            <CSVLink
              data={data ? data : ""}
              filename={`Campaign-Report-${new Date().toLocaleDateString()}`}
              asyncOnClick={true}
              target="_blank"
              className="btn btn--medium btn--mobile-small"
            >
              Download Report
            </CSVLink>
          </li>
        </div> */}
      </div>
      <div className="main__body main__body--flex main__body--flex-alt">
        <div className="boxes-info">
          <ul>
            <li>
              <div className="info-box">
                <p>Total entries</p>
                <h1>{stats}</h1>
              </div>
            </li>
          </ul>
        </div>
        <br />
        <div className="chart">
          <select
            className="form-control"
            value={selectedmonths}
            onChange={(e) => {
              setSelectedmonths(+e.currentTarget.value);
            }}
          >
            <option value="12">12 months</option>
            <option value="6">6 months</option>
            <option value="3">3 months</option>
            <option value="1">1 month</option>
            <option value="2">2 weeks</option>
            <option value="0">1 week</option>
          </select>

          <div className="graph">
            {graphLabels && (
              <Graph
                graphLabels={graphLabels}
                selectedmonths={selectedmonths}
              />
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <p>Currently there are no any stats to display.</p>
  );
}

export default Overview;
