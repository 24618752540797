import { useRequiredCampaignState } from "../../helpers/campaignStates";

export default function Closed() {
  useRequiredCampaignState("closed");

  return (
    <div className="container">
      <div className="confirmation">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="left">
              <img src="/assets/images/logo-spray.png" className="sprayLogo" alt="Spray Logo" />
              <p>Thank you for your interest.</p>
              <p>This campaign is closed now.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <img src="/assets/images/logo.png" alt="Logo" className="logoFooter" />
      </div>
    </div>
  );
}
